import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of, shareReplay, tap } from 'rxjs';
import { CdkStepper, CdkStepperModule, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatIconModule } from '@angular/material/icon';

import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import {
    Activity,
    Machine,
    SignOffDialogConfig,
    SignOffDialogData,
    SignOffMachine,
    TimeModeEntry,
} from '@data-terminal/shared-models';
import { SignOffProcessService } from '@data-terminal/feature-dialogs';
import { RequestMetadata } from '@data-terminal/utils';

import { SignOffWorkstationsModule } from '../../components/sign-off-workstations/sign-off-workstations.component';
import { SignOffEmployeesModule } from '../../components/sign-off-employees/sign-off-employees.component';
import { SignOffOperationsModule } from '../../components/sign-off-operations/sign-off-operations.component';
import { SignOffSummaryModule } from '../../components/sign-off-summary/sign-off-summary.component';

@Component({
    standalone: true,
    templateUrl: './sign-off-dialog.component.html',
    styleUrls: ['./sign-off-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CdkStepper],
    imports: [
        HdmuiComponentsModule,
        MatStepperModule,
        MatToolbarModule,
        MatButtonModule,
        CommonModule,
        SignOffWorkstationsModule,
        SignOffEmployeesModule,
        SignOffOperationsModule,
        SignOffSummaryModule,
        TranslateModule,
        MatIconModule,
        CdkStepperModule,
    ],
})
export class SignOffDialogComponent {
    public machines: Machine[];
    public unsubmittedTimeEntries: TimeModeEntry[];
    public machinesToSignOff$: Observable<SignOffMachine[]>;
    public config$: Observable<SignOffDialogConfig>;
    public otherActivities$: Observable<RequestMetadata<Activity[]>> = of({ isLoading: true });

    public dialogForm = new UntypedFormGroup({
        workstations: new UntypedFormControl(null, Validators.required),
        operations: new UntypedFormControl(null, Validators.required),
        employees: new UntypedFormControl(null, Validators.required),
        summary: new UntypedFormControl(null),
    });
    @ViewChild(MatStepper) private readonly stepper?: MatStepper;

    public isPreviousButtonDisable = true;
    public isActionButtonDisable = false;
    public actionButtonText = 'NEXT';
    public currentStep!: string;

    constructor(
        private readonly signOffProcessService: SignOffProcessService,
        private readonly dialogRef: MatDialogRef<SignOffDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly dialogData: SignOffDialogData
    ) {
        this.machines = this.dialogData.data;
        this.config$ = this.signOffProcessService.getConfig().pipe(tap((data) => this.setButtonsByConfig(data)));
        this.machinesToSignOff$ = this.signOffProcessService.getMachineToSignOff().pipe(shareReplay());
        this.otherActivities$ = this.dialogData.otherActivities$;
        this.unsubmittedTimeEntries = this.dialogData.unsubmittedTimeEntries;
    }

    public onEmployeesStateChange(signOffMachines: SignOffMachine[]): void {
        this.signOffProcessService.onEmployeesStateChange(signOffMachines);
    }

    public onOperationStateChange(signOffMachines: SignOffMachine[]): void {
        this.signOffProcessService.onOperationStateChange(signOffMachines);
    }

    public onWorkstationStateChange(machines: Machine[]): void {
        this.signOffProcessService.onWorkstationStateChange(machines);
    }

    public onCancelClick(): void {
        this.dialogRef.close(false);
    }

    public onPreviousClick(): void {
        this.stepper?.previous();
    }

    public onNextClick(): void {
        if (this.actionButtonText === 'NEXT') {
            this.stepper?.next();
        } else {
            this.dialogRef.close(true);
        }
    }

    public isStepValid(formName: string, isValid: boolean): void {
        if (isValid) {
            this.dialogForm.get(formName)?.setValue(true);
            if (formName === this.currentStep) {
                this.isActionButtonDisable = false;
            }
        } else {
            this.dialogForm.get(formName)?.setValue(null);
            if (formName === this.currentStep) {
                this.isActionButtonDisable = true;
            }
        }
    }

    public actionButtonHandle($event: StepperSelectionEvent): void {
        this.currentStep = $event.selectedStep.state;
        this.isActionButtonDisable = !!this.dialogForm.controls[this.currentStep]?.invalid;
        this.actionButtonText = this.stepper?.steps.length === $event.selectedIndex + 1 ? 'SIGN_OFF' : 'NEXT';
        this.isPreviousButtonDisable = $event.selectedIndex === 0;
    }

    private setButtonsByConfig(data: SignOffDialogConfig): void {
        const views = Object.keys(data).filter((key: string) => data[key as keyof SignOffDialogConfig]);
        this.currentStep = this.currentStep || views[0];
        this.actionButtonText = views.length === 1 ? 'SIGN_OFF' : 'NEXT';
    }
}
