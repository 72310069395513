import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'lodash';

import {
    ActivityState,
    Operation,
    OperationCardAttribute,
    OperationFilter,
    OperationFilterAdvanced,
    OperationFilterCheckboxes,
    OperationFilterDates,
    OperationFilterDueDate,
    OperationNeedsAvailable,
} from '@data-terminal/shared-models';

import { OperationStatePipe } from '../../../../../../ui-smart/src/lib/components/operation-card/operation-state/operation-state.pipe';

const operationStatePipe = new OperationStatePipe();

export function searchInputFilter(
    searchText: string,
    operations: Operation[],
    datePipe: DatePipe,
    translate: TranslateService,
    attrOrder: OperationCardAttribute[]
): Operation[] {
    searchText = searchText.toLowerCase();
    searchText = searchText.trim();
    return operations.filter((operation) => {
        if (searchText === '') {
            return true;
        }
        if (operation.running) {
            return true;
        }

        let isIncluded = false;

        const attributesToCheck = [...attrOrder, OperationCardAttribute.jobNumber];

        attributesToCheck.forEach((attribute) => {
            if (attribute === OperationCardAttribute.opState) {
                const i18nKey = operationStatePipe.transform(operation);
                const stateTest = translate.instant(i18nKey);
                if (stateTest?.toLowerCase().includes(searchText)) {
                    isIncluded = true;
                }
            }

            if (attribute === OperationCardAttribute.opPlannedStart || attribute === OperationCardAttribute.dueDate) {
                const date = datePipe.transform(operation[attribute], 'dd/MM/YY hh:mm a');
                if (date?.toLowerCase().includes(searchText)) {
                    isIncluded = true;
                }
            }

            const value = operation[attribute as keyof Operation];
            if (value?.toString()?.toLowerCase().includes(searchText)) {
                isIncluded = true;
            }
        });

        return isIncluded;
    });
}

export function filterOperations(filter: OperationFilter | undefined, operations: Operation[]): Operation[] {
    if (filter) {
        operations = dueDateFilter(filter?.dueDate, operations);
        operations = datesFilter(filter?.plannedStart, operations);
        operations = checkboxFilter(filter?.checkboxes, operations);
        operations = advancedPartFilter(filter?.advanced, operations);
    }
    return operations;
}

export function dueDateFilter(dueDate: OperationFilterDueDate, operations: Operation[]): Operation[] {
    if (dueDate.start && dueDate.end) {
        const start = new Date(dueDate.start).getTime();
        const end = new Date(dueDate.end).getTime();

        operations = operations.filter((op) => (op.dueDate > start && op.dueDate < end) || op.running);
    }
    return operations;
}

export function datesFilter(dates: OperationFilterDates, operations: Operation[]): Operation[] {
    if (dates.hours !== null) {
        const current = new Date();
        const hours: number = 1000 * 60 * 60 * +dates.hours;
        const planned = new Date(current.getTime() + hours);
        operations = operations.filter(
            (op) => (op.opPlannedStart > current.getTime() && op.opPlannedStart < planned.getTime()) || op.running
        );
    }
    return operations;
}

export function advancedPartFilter(advanced: OperationFilterAdvanced, operations: Operation[]): Operation[] {
    return operations;
}

export function checkboxFilter(checkboxes: OperationFilterCheckboxes, operations: Operation[]): Operation[] {
    const isPaused = (op: Operation): boolean => op.opState !== ActivityState.SUSPENDED;
    const isFinished = (op: Operation): boolean => op.opState !== ActivityState.COMPLETED;
    const isStartExceeded = (op: Operation): boolean =>
        !(
            op.opState === ActivityState.WAITING &&
            op.opPlannedStart < new Date().getTime() &&
            op.opNeedsAvailable === OperationNeedsAvailable.GREEN
        );
    const isCanBeStarted = (op: Operation): boolean =>
        !(
            op.opState === ActivityState.WAITING &&
            op.opPlannedStart > new Date().getTime() &&
            op.opNeedsAvailable === OperationNeedsAvailable.GREEN
        );
    const isWaitingWarning = (op: Operation): boolean =>
        !(op.opState === ActivityState.WAITING && op.opNeedsAvailable === OperationNeedsAvailable.YELLOW);
    const isWaitingError = (op: Operation): boolean =>
        !(op.opState === ActivityState.WAITING && op.opNeedsAvailable === OperationNeedsAvailable.RED);

    operations = !checkboxes.paused ? operations.filter(isPaused) : operations;
    operations = operations.filter(isFinished);
    operations = !checkboxes.startExceeded ? operations.filter(isStartExceeded) : operations;
    operations = !checkboxes.canBeStarted ? operations.filter(isCanBeStarted) : operations;
    operations = !checkboxes.waitingWarning ? operations.filter(isWaitingWarning) : operations;
    operations = !checkboxes.waitingError ? operations.filter(isWaitingError) : operations;

    return operations;
}

export function hasAnyFilterApplied(filter: OperationFilter | undefined): boolean {
    if (!filter) {
        return false;
    }

    const filterValues: (string | number | boolean)[] = [];

    for (const value of Object.values(filter)) {
        if (isObject(value)) {
            for (const v of Object.values(value)) {
                filterValues.push(v);
            }
        } else {
            filterValues.push(value);
        }
    }
    return filterValues.some((value) => !!value || value === 0);
}
