<div class="toolbar-button-panel">
    @if (!stopButtonOnly) {
        <button
            mat-icon-button
            [disabled]="this.pauseButtonDisabled"
            [class.toolbar-button-panel__active-action-button]="isPauseHighlighted"
            (click)="onPauseButtonClick()"
        >
            <mat-icon svgIcon="hdmui:videoControlPause"></mat-icon>
        </button>
    }

    <button
        mat-icon-button
        [disabled]="stopButtonDisabled"
        [class.toolbar-button-panel__active-action-button]="isStopHighlighted"
        (click)="onStopButtonClick()"
    >
        <mat-icon svgIcon="hdmui:videoControlStop"></mat-icon>
    </button>

    @if (!stopButtonOnly) {
        <button mat-icon-button [disabled]="reportButtonDisabled" (click)="onReportButtonClick()">
            <mat-icon svgIcon="hdmui:totalisatorStand"></mat-icon>
        </button>

        @if (operationSettings?.materialConsumption) {
            <button mat-icon-button (click)="onConsumptionButtonClick()" [disabled]="this.consumptionsButtonDisabled">
                <mat-icon svgIcon="hdmui:consumption"></mat-icon>
            </button>
        }
    }
</div>
