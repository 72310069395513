import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { catchError, filter, map, of, switchMap, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

import {
    ActivityState,
    OperationAction,
    OperationActionType,
    OperationSettingsEntry,
} from '@data-terminal/shared-models';

import {
    ReportLastAmountDialogClose,
    ReportLastAmountDialogData,
    ReportLastAmountsDialogComponent,
} from './report-last-amounts-dialog/report-last-amounts-dialog.component';
import { ConsumptionDialogComponent, ConsumptionDialogData } from './consumption-dialog/consumption-dialog.component';
import { Consumption } from '../../../models/consumption.model';
import { ConsumptionsApiService } from '../../../services/consumptions-api/consumptions-api.service';
import { Material } from '../../../models/material.interface';

@Component({
    selector: 'data-terminal-operation-toolbar-buttons',
    templateUrl: './operation-toolbar-buttons.component.html',
    styleUrls: ['./operation-toolbar-buttons.component.scss'],
})
export class OperationToolbarButtonsComponent implements OnChanges, OnInit {
    @Input() public opState!: ActivityState;
    @Input() public plannedGoodAmount!: number;
    @Input() public plannedWasteAmount!: number;
    @Input() public currentGoodAmount!: number;
    @Input() public currentWasteAmount!: number;
    @Input() public stopButtonOnly = false;
    @Input() public isCounterBox = false;
    @Input() public counterBoxTimestamp = '';
    @Input() public captainFlag = false;
    @Input() public isManualMachine = false;
    @Input() public operationSettings?: OperationSettingsEntry;

    @Output() public sendReport: EventEmitter<OperationAction> = new EventEmitter<OperationAction>();
    @Output() public consumptionsReport: EventEmitter<Consumption[]> = new EventEmitter<Consumption[]>();

    public areActionButtonsDisabled = true;
    public isStopButtonDisabled = true;
    public isStopHighlighted = false;
    public isPauseHighlighted = false;

    constructor(
        private readonly dialog: MatDialog,
        private readonly consumptionsApiService: ConsumptionsApiService
    ) {}

    public ngOnInit(): void {
        this.updateButtonsConfig();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.opState) {
            this.updateButtonsConfig();
        }
    }

    public get pauseButtonDisabled(): boolean {
        return this.areActionButtonsDisabled || !this.captainFlag;
    }

    public get stopButtonDisabled(): boolean {
        return this.isStopButtonDisabled || !this.captainFlag;
    }

    public get reportButtonDisabled(): boolean {
        return this.areActionButtonsDisabled || !this.captainFlag || this.isCounterBox || this.isManualMachine;
    }

    public get consumptionsButtonDisabled(): boolean {
        return this.areActionButtonsDisabled || !this.captainFlag || !this.operationSettings?.materialConsumption;
    }

    public onPauseButtonClick(): void {
        if (this.pauseButtonDisabled) {
            return;
        }

        const headerKey = this.isManualMachine
            ? 'DC.REPORT_LAST_AMOUNTS.PAUSE_HEADER_MANUAL_DEVICE'
            : 'DC.REPORT_LAST_AMOUNTS.PAUSE_HEADER';

        this.openReportLastAmountsDialog(OperationActionType.PAUSE, headerKey, true);
    }

    public onStopButtonClick(): void {
        if (this.stopButtonDisabled) {
            return;
        }

        if (this.stopButtonOnly) {
            this.sendReport.emit({
                actionType: OperationActionType.STOP,
            } as OperationAction);
        } else {
            const headerKey = this.isManualMachine
                ? 'DC.REPORT_LAST_AMOUNTS.FINISH_HEADER_MANUAL_DEVICE'
                : 'DC.REPORT_LAST_AMOUNTS.FINISH_HEADER';

            this.openReportLastAmountsDialog(OperationActionType.STOP, headerKey, true);
        }
    }

    public onReportButtonClick(): void {
        if (this.reportButtonDisabled) {
            return;
        }

        this.openReportLastAmountsDialog(OperationActionType.ADD_REPORT, 'DC.REPORT_LAST_AMOUNTS.ADD_REPORT_HEADER');
    }

    public onConsumptionButtonClick(): void {
        if (this.consumptionsButtonDisabled) {
            return;
        }

        this.consumptionsApiService
            .getMaterials()
            .pipe(
                take(1),
                catchError(() => of([])),
                map((materials) =>
                    this.dialog.open<ConsumptionDialogComponent, ConsumptionDialogData, Consumption[]>(
                        ConsumptionDialogComponent,
                        {
                            disableClose: true,
                            autoFocus: false,
                            data: { allMaterials: materials, batchAllowed: !!this.operationSettings?.batchReport },
                            maxHeight: '90vh',
                            height: '100%',
                            width: '320px',
                        }
                    )
                ),
                switchMap((dialog) => dialog.afterClosed())
            )
            .subscribe((consumptions) => {
                this.consumptionsReport.emit(consumptions || []);
            });
    }

    private openReportLastAmountsDialog(
        dialogType: OperationActionType,
        i18nHeaderKey: string,
        withMaterialConsumption = false
    ): void {
        const allowConsumption = withMaterialConsumption && !!this.operationSettings?.materialConsumption;

        const getFormData: (materials: Material[]) => ReportLastAmountDialogData = (materials) => {
            return {
                dialogType,
                i18nHeaderKey,
                plannedGoodAmount: this.plannedGoodAmount,
                plannedWasteAmount: this.plannedWasteAmount,
                currentGoodAmount: this.currentGoodAmount,
                currentWasteAmount: this.currentWasteAmount,
                counterBoxTimestamp: this.counterBoxTimestamp,
                isManualMachine: this.isManualMachine,
                consumptionsDialogData: allowConsumption
                    ? {
                          allMaterials: materials,
                          batchAllowed: !!this.operationSettings?.batchReport,
                      }
                    : undefined,
            };
        };

        (allowConsumption
            ? this.consumptionsApiService.getMaterials().pipe(
                  take(1),
                  catchError(() => of([]))
              )
            : of([] as Material[])
        )
            .pipe(
                switchMap((materials) =>
                    this.dialog
                        .open<
                            ReportLastAmountsDialogComponent,
                            ReportLastAmountDialogData,
                            ReportLastAmountDialogClose
                        >(ReportLastAmountsDialogComponent, {
                            disableClose: true,
                            autoFocus: false,
                            data: getFormData(materials),
                            maxHeight: '90vh',
                            height: '100%',
                            width: '320px',
                        })
                        .afterClosed()
                )
            )
            .pipe(filter((d) => !!d))
            .subscribe((data) => {
                if (data) {
                    this.sendReport.emit(data.action);

                    if (data.consumptions) {
                        this.consumptionsReport.emit(data.consumptions);
                    }
                }
            });
    }

    private updateButtonsConfig(): void {
        this.areActionButtonsDisabled = true;
        this.isStopButtonDisabled = true;
        this.isPauseHighlighted = false;
        this.isStopHighlighted = false;

        if (this.opState === ActivityState.SETUP || this.opState === ActivityState.IN_PROGRESS) {
            this.areActionButtonsDisabled = false;
            this.isStopButtonDisabled = false;
            return;
        }

        if (this.opState === ActivityState.COMPLETED) {
            this.isStopHighlighted = true;
            return;
        }

        if (this.opState === ActivityState.PAUSED || this.opState === ActivityState.SUSPENDED) {
            this.isPauseHighlighted = true;
            if (this.opState === ActivityState.SUSPENDED) {
                this.isStopButtonDisabled = false;
            }
        }
    }
}

@NgModule({
    declarations: [OperationToolbarButtonsComponent],
    exports: [OperationToolbarButtonsComponent],
    imports: [MatButtonModule, MatIconModule, CommonModule],
})
export class OperationToolbarButtonsModule {}
