<hdmui-base-dialog
    (cancelClicked)="cancel()"
    (okClicked)="submit()"
    [dialogOptions]="DIALOG_OPTION"
    [okEnabled]="isYesButtonEnabled"
    [okText]="'Yes'"
    [cancelText]="'No'"
    [closeInHeaderVisible]="false"
    [contentNoPadding]="true"
>
    <div class="ongoing-operation__container">
        <h2 class="hdmui-m-0">{{ 'DC.ONGOING_OPERATION_DIALOG.HEADER' | translate }}</h2>

        <div class="ongoing-operation__info">
            <p class="hdmui-m-0">
                {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.DESCRIPTION' | translate: { opName: runningOperation.opName } }}
            </p>
            <p class="hdmui-m-0 hdmui-mt-4">{{ 'DC.ONGOING_OPERATION_DIALOG.QUESTION' | translate }}</p>
        </div>

        <form [formGroup]="formGroup">
            <div class="ongoing-operation__buttons">
                <mat-radio-group name="operationAction" formControlName="actionType">
                    <mat-radio-button name="operationAction" [value]="PAUSE_ACTION">{{
                        'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.PAUSE' | translate
                    }}</mat-radio-button>
                    <mat-radio-button name="operationAction" [value]="STOP_ACTION">{{
                        'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.FINISH' | translate
                    }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <p
                class="hdmui-caption"
                *ngIf="dialogData.counterboxTimestamp !== undefined && dialogData.counterboxTimestamp !== ''"
            >
                {{ 'DC.REPORT_LAST_AMOUNTS.COUNTERBOX_MSG' | translate }}: {{ dialogData.counterboxTimestamp }}
            </p>
            <div>
                <mat-form-field>
                    <mat-label> {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.GOOD_AMOUNT' | translate }} </mat-label>
                    <input
                        matInput
                        formControlName="goodAmount"
                        (blur)="onInputOut()"
                        id="inputGoodAmount"
                        #goodAmountInput
                        mask="separator.0"
                    />
                </mat-form-field>
                <mat-form-field>
                    <mat-label> {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.WASTE_AMOUNT' | translate }} </mat-label>
                    <input matInput formControlName="wasteAmount" (blur)="onInputOut()" mask="separator.0" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label> {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.COMMENT' | translate }} </mat-label>
                    <textarea matInput rows="3" formControlName="comment"></textarea>
                </mat-form-field>
                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 ongoing-operation__form_info">
                    {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.MANDATORY' | translate }}
                </p>
                <data-terminal-amount-report-details
                    [formData]="formData$ | async"
                    [operationData]="operationData$ | async"
                    [isFinishOperation]="formGroup.controls.actionType.value === this.STOP_ACTION"
                    [isManualMachine]="!!dialogData.isManualMachine"
                ></data-terminal-amount-report-details>
            </div>
        </form>
    </div>
</hdmui-base-dialog>
