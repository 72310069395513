<mat-card appearance="outlined" class="sign-off-employee-card hdmui-p-4">
    <div class="sign-off-employee-card__headers">
        <h1 class="hdmui-font-serif hdmui-m-0 sign-off-employee-card__info-text">
            {{ machine.machineName }}
        </h1>
        <h1 class="hdmui-font-bold hdmui-m-0">
            {{ machine.machineId }}
        </h1>
    </div>
    <p class="sign-off-employee-card__info hdmui-m-0 hdmui-mt-3">
        {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.DESCRIPTION' | translate: { opName: machine.runningOperation?.opName } }}
    </p>
    <form [formGroup]="formGroup">
        <div class="report-form">
            <div class="sign-off-employee-card__buttons">
                <mat-radio-group
                    [value]="PAUSE"
                    [name]="'operation' + machine.machineId"
                    formControlName="operationAction"
                >
                    <mat-radio-button [name]="'operation' + machine.machineId" [value]="PAUSE">
                        {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.PAUSE' | translate }}
                    </mat-radio-button>
                    <mat-radio-button [name]="'operation' + machine.machineId" [value]="FINISH">
                        {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.FINISH' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div>
                <mat-form-field>
                    <mat-label> {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.GOOD_AMOUNT' | translate }} </mat-label>
                    <input matInput formControlName="goodCount" (blur)="onInputOut()" mask="separator.0" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label> {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.WASTE_AMOUNT' | translate }} </mat-label>
                    <input matInput formControlName="wasteCount" (blur)="onInputOut()" mask="separator.0" />
                </mat-form-field>
                <mat-form-field>
                    <mat-label> {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.COMMENT' | translate }} </mat-label>
                    <textarea matInput rows="3" formControlName="comment"></textarea>
                </mat-form-field>
                <p class="hdmui-caption hdmui-m-0 hdmui-p-0 sign-off-employee-card__form_info">
                    {{ 'DC.SIGN_OFF_DIALOG.OPERATIONS.CARD.MANDATORY' | translate }}
                </p>
            </div>
            <data-terminal-amount-report-details
                [formData]="formData$ | async"
                [operationData]="operationData$ | async"
                [isFinishOperation]="formGroup.controls.operationAction.value === FINISH"
                [isManualMachine]="false"
            ></data-terminal-amount-report-details>
        </div>
    </form>
</mat-card>
